import Image from 'next/image'
import { useState } from 'react'
import logo from '../public/images/POKMI_LOGO.png'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import styles from '../styles/Footer.module.scss' 
import { useWalletContext } from '../utils/wallet'
import { truncateWallet } from '../utils'
import { truncate } from 'fs'
import { useRouter } from 'next/router'
import Link from 'next/link'



export default function Footer(props){
	const router = useRouter()
	const defaultOnClick = () => {
	  router.push('/')
	}
  return (
	<footer className={styles.footer}>
		<picture onClick={defaultOnClick}>
			<source srcSet="/images/POKMI_LOGO.png" type="image/png"/>
			<img alt="Pokmi Logo" className={styles.img} src="/images/POKMI_LOGO.png" />
		</picture>
		<div className={styles.divider}></div>
		<div className={styles.footerLink}>
			<span>2022 Pokmi. All right reserved.</span>
			{/* <Link href="/rules">Rules</Link> */}
			<Link href="https://legal.pokmi.com/privacy-policy/">Privacy Policy</Link>
		</div>
	</footer>
  )
}