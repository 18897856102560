import Image from 'next/image'
import { CSSProperties, useEffect, useState } from 'react'
import logo from '../public/images/POKMI_LOGO.png'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import styles from '../../styles/BuyPokumi.module.scss' 
import { useWalletContext } from '../../utils/wallet'
import { truncateWallet } from '../../utils'
import { truncate } from 'fs'
import { useRouter } from 'next/router'
import { useMediaQuery } from 'react-responsive'
import SendWarriorsModal from '../Modals/SendWarriorsModal'
import { useModalContext } from '../../context/modalContext'
import SyncLoader from 'react-spinners/SyncLoader'
import Link from 'next/link'

const override: CSSProperties = {
	display: "block",
	margin: "0 auto",
  };
export default function BuyPokumi(){
    const pokumiHref = 'https://pokmi.com/profile/pokumi'

	const isSmallScreen = !useMediaQuery({ minWidth: 550 });


	return (
	<div className={styles.containerBuyPokumi}>
		<Link href={pokumiHref} passHref>
          <a
            target='_blank'
            rel='noreferrer'
            className={styles.actionWarButton}
          >
            Buy Pokumi
          </a>
        </Link>
	</div>
	)
}