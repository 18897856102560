import { CSSProperties, useEffect, useState } from 'react'
import { Button, BUTTON_SIZES, Modal } from '@thepokencompany/ui'
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from '../base/Toaster'
import TextInput from '../base/TextInput'

import { formatPokenAmount } from '../../utils'
import { useModalContext } from '../../context/modalContext'
import { useWalletInfosContext } from '../../context/walletInfosContext'
import { useWalletContext } from '../../context/walletContext'
import styles from '../../styles/ActionWar.module.scss' 
const ConfirmForm = ({ amount, chain, onConfirm }) => {
  return (
    <div className={'flex flex-col items-center'}>
      <Modal.Header className='mb-4'>
        You&apos;re about to withdraw {formatPokenAmount(amount)} PKN on {chain}
      </Modal.Header>
      <Button onClick={onConfirm}>Confirm</Button>
    </div>
  )
}
const override: CSSProperties = {
	display: "block",
	margin: "0 auto",
  };
export default function WithdrawPoolingModal(props) {
	const { warriorsAvailable, fetchWarriorsReady, onConfirm, onClose } = props;
	
	const { openModal, closeModal } = useModalContext()
	const { infos } = useWalletInfosContext()
	const { withdrawPooledPokens } = useWalletContext()
	const [warriorsReady, setWarriorsReady] = useState(warriorsAvailable)
	const [warriorsToSend, setWarriorsToSend] = useState(0)
	const onChangeInput = (evt) => setWarriorsToSend(evt.target.valueAsNumber)

	const confirmSendWarrior = async () => {
		const sans = await onConfirm(warriorsToSend)
		onClose()
	}

  const showConfirmModal = () => {
    openModal({
      content: <>
	  	<Modal.Header>Confirm your participation</Modal.Header>
		<Modal.Separator />
	  	<span>Confirm to engage {warriorsToSend} warriors at to war !</span>
		<Button className={styles.btnConfirmSend} onClick={confirmSendWarrior} size={BUTTON_SIZES.small} outlined>Confirm</Button>
	  </>
    })
  }

  return (
	<>
		<Modal.Header>{`Send your warriors to fight`}</Modal.Header>
		<Modal.Separator />
		<div className=''>
			{warriorsReady>1 
				? <div>You&apos;ve {warriorsReady} warriors ready for the fight.</div> 
				: <div>You&apos;ve {warriorsReady} warrior ready for the fight.</div>}
			{warriorsReady 
				? <div className='flex flex-col my-2'>
					<TextInput
					type='number'
					min={1}
					max={warriorsReady}
					className={styles.sendInput}
					value={warriorsToSend}
					onChange={onChangeInput}
					/>
					<Button disabled={warriorsToSend < 0} className='w-full mb-4' onClick={() => showConfirmModal()}>
						Send
					</Button>
				</div> 
				: <></>
			}
		</div>
	</>
  )
}
