import Image from 'next/image'
import { CSSProperties, useEffect, useState } from 'react'
import logo from '../public/images/POKMI_LOGO.png'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import styles from '../../styles/ActionWar.module.scss' 
import { useWalletContext } from '../../utils/wallet'
import { truncateWallet } from '../../utils'
import { truncate } from 'fs'
import { useRouter } from 'next/router'
import { useMediaQuery } from 'react-responsive'
import SendWarriorsModal from '../Modals/SendWarriorsModal'
import { useModalContext } from '../../context/modalContext'
import SyncLoader from 'react-spinners/SyncLoader'

const override: CSSProperties = {
	display: "block",
	margin: "0 auto",
  };
export default function ActionWar(props){
	const { playerWarriors, fetchWarriors, onConfirm } = props;

	const { openModal, closeModal } = useModalContext();
	const isSmallScreen = !useMediaQuery({ minWidth: 550 });
	const [address, setAddress] = useState('0x212c4......53be1')
	const [warriorAvailable, setWarriorAvailable] = useState(0);
	const showSendWarriorsModal = () => {
		openModal({
		  content: (
			<SendWarriorsModal
				warriorsAvailable={playerWarriors}
				fetchWarriorsReady={fetchWarriors}
				onConfirm={onConfirm}
				onClose={() => {
					closeModal()
				}}
			/>
		  )
		})
	  }
	useEffect(() => {
		isSmallScreen ? setAddress('0x212c4......53be1') : setAddress('0x212c489b399349be22d5311c9ca3194961853be1')
	}, [isSmallScreen])
	useEffect(() => {
		setWarriorAvailable(playerWarriors)
	}, [playerWarriors])
	return (
	<div className={styles.containerActionWar}>
		<span className={styles.actionWarInfo}>{playerWarriors>1 
				? <div>You&apos;ve {playerWarriors} warriors ready for the fight.</div> 
				: <div>You&apos;ve {playerWarriors} warrior ready for the fight.</div>}</span>
		{/* <span >{address}</span> */}
		{fetchWarriors 
			? <div  onClick={showSendWarriorsModal} className={styles.actionWarButton}>Send my warriors</div> 
			: <div className={styles.actionWarButton}>
				<SyncLoader
					color={'#000000'}
					loading={!fetchWarriors}
					cssOverride={override}
					size={10}
					aria-label="sync Spinner"
					data-testid="sync"
				/>
			</div>
		}
	</div>
	)
}