import Image from 'next/image'
import { useEffect, useState } from 'react'
import logo from '../public/images/POKMI_LOGO.png'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import styles from '../styles/Header.module.scss' 
import { useWalletContext } from '../utils/wallet'
import { truncateWallet } from '../utils'
import { truncate } from 'fs'
import { useRouter } from 'next/router'
import { useConnectContext } from '../context/connectContext'
import { useModalContext } from '../context/modalContext'
import Button from './base/Button'
import { BUTTON_SIZES } from '@thepokencompany/ui'
import { useUserContext } from '../context/userContext'
import ImageWrapper from './base/ImageWrapper'
import clsx from 'clsx'



export default function Header(props){
	const router = useRouter()
	const defaultOnClick = () => {
	  router.push('/')
	}
	const { user, onDisconnect, onConnect } = props
	const src ='/images/Poken.png';
	const rest = {
        // alt:'user-avatar',
        // onClick:() => {},
        // width:150,
        // height:150,
        // forceGifResize:{props.forceGifResize}
        // resize:{ width: 150, height: 150 },
        // soft:{soft}
        // noPlayIcon:true,
        // rounded:false
	}
	// const { openLogin } = useConnectContext()
	// const { user, disconnect } = useUserContext()
	const [isLogging, setIsLogging] = useState(false)

	useEffect(() => {
		user ? setIsLogging(true) : setIsLogging(false)
	}, [user])
	
	return (
		<header className={styles.header}>
			<div className={styles.loginButton}></div>
			<picture onClick={defaultOnClick}>
				<source srcSet="/images/POKMI_LOGO.png" type="image/png"/>
				<img alt="Pokmi Logo" className={styles.img} src="/images/POKMI_LOGO.png" />
			</picture>
			{isLogging 
				? <div className={styles.loginButton}>
					<ImageWrapper
						{...rest}
						src={src}
						className={clsx('object-cover rounded-full')}
						/>
					{/* <span>{user?.name}</span> */}
					<div
                  className='text-sm mb-2 mt-3 text-red'
                  onClick={onDisconnect}
                >
                  <span>Log out</span>
                </div>
				</div> 
				: <Button onClick={onConnect} size={BUTTON_SIZES.small} className={styles.loginButton}>
					Login
				</Button>
			}
		</header>
	)
}