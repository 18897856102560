import { useMemo } from 'react'

export default function ImageWrapper(props) {
  const {
    src,
    type,
    muted,
    threshold,
    poster,
    parentRef,
    blurred,
    noPlayIcon,
    soft,
    playInViewPort,
    priority,
    ...rest
  } = props

  const onImgError = (e) => {
    e.target.src = '/images/Image_Placeholder.png'
  }

  const image = useMemo(() => {
    const imgSrc = src || ''
    return (
      <picture {...rest}>
        <source srcSet={imgSrc} type="image/png"/>
        <img 
          alt={rest.alt}
          src={imgSrc}
        />
      </picture>
    )
  }, [src, props.className])

  return image
}
