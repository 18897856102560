import Image from 'next/image'
import { useState } from 'react'
import logo from '../public/images/POKMI_LOGO.png'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import styles from '../styles/RewardsWar.module.scss' 
import { useWalletContext } from '../utils/wallet'
import { truncateWallet } from '../utils'
import { truncate } from 'fs'
import { useRouter } from 'next/router'



export default function RewardsWar(props){
	return (
		<div className={styles.containerRewardWar}>
		<h5 className={styles.h5}>Reward.</h5>
		<div className={styles.rewardMap}>
			<picture className={styles.picture}>
				<source srcSet="/images/land_173_-63.png" type="image/png"/>
				<img  alt="map land"  src="/images/land_173_-63.png" />
			</picture>
			{/* <div className={styles.rewardMapInfo}>
				<span>Pokmi Land</span>
				<span
					>X : 171 <br />
					Y : -67
				</span>
			</div> */}
		</div>
	</div>
	)
}