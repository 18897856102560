import Image from 'next/image'
import { CSSProperties, useEffect, useState } from 'react'
import logo from '../public/images/POKMI_LOGO.png'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import styles from '../styles/RemainingWar.module.scss' 
import { useWalletContext } from '../utils/wallet'
import { truncateWallet } from '../utils'
import { truncate } from 'fs'
import { useRouter } from 'next/router'
import PuffLoader from 'react-spinners/PuffLoader'

const override: CSSProperties = {
	display: "block",
	margin: "0 auto",
  };


export default function RemainingWar(props){
	const { dataRemaining, nbrOfDay } = props;
	const [holders, setHolders] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if(dataRemaining?.bestHolders){
			setHolders(dataRemaining?.bestHolders);
			setLoading(false);
		}
	}, [dataRemaining])
	return (
		<div className={styles.containerRemainingWar}>
		<h5 className={styles.h5} data-text="warriors.">
			Remaining <br />
			Pokumi warriors.
		</h5>
		{ typeof nbrOfDay === "number" 
			? <span className={styles.remainingGames}>Total games : { nbrOfDay }</span>
			: <span className={styles.remainingGames}>Registration is not complete</span>
		}
		
		<ul className={styles.remainingGamesList}>
			<li key="headerRemainingList" className={styles.remainingGamesListHead}>
				<span className={styles.remainingGamesListLeft}>
					Remaining Holders
				</span>
				<span className={styles.remainingGamesListRight}>
					Remaining Warriors
				</span>
			</li>
			{!loading 
				? (dataRemaining?.totalHolders != 0 && holders?.map((holder, index) =>{
				return (index === 0) 
						? <li key={index} className={styles.remainingGamesFirstHolder} >
								<span className={styles.remainingGamesListLeft}>
								<picture  className={styles.picture}>
									<source srcSet="/images/Poken.png" type="image/png"/>
									<img alt="Pokmi Logo" src="/images/Poken.png" className={styles.miniLogo}/>
								</picture>
								{holder.holder.slice(0,2) == '0x' 
								? <p><span className={styles.holderName}>{ holder.holder.slice(0,5)+'...'+holder.holder.slice(-5) }</span></p>
								: <span className={styles.holderName}>{ holder.holder }</span>
								}
								</span>
								<span className={styles.remainingGamesListRight}>
									{
										holder.warriors < 10
											? "0" + holder.warriors
											: holder.warriors
									}
								</span>
							</li>						
						: (index === holders.length - 1)
							? <li key={index} className={styles.remainingGamesLastHolder} >
								<span className={styles.remainingGamesListLeft}>
								<picture className={styles.picture}>
									<source srcSet="/images/Poken.png" type="image/png"/>
									<img alt="Pokmi Logo" src="/images/Poken.png" className={styles.miniLogo}/>
								</picture>
								{holder.holder.slice(0,2) == '0x' 
								? <p><span className={styles.holderName}>{ holder.holder.slice(0,5)+'...'+holder.holder.slice(-5) }</span></p>
								: <span className={styles.holderName}>{ holder.holder }</span>
								}
								</span>
								<span className={styles.remainingGamesListRight}>
									{
										holder.warriors < 10
											? "0" + holder.warriors
											: holder.warriors
									}
								</span>
							</li>
							: <li key={index}>
								<span className={styles.remainingGamesListLeft}>
								<picture className={styles.picture}>
									<source srcSet="/images/Poken.png" type="image/png"/>
									<img alt="Pokmi Logo" src="/images/Poken.png" className={styles.miniLogo}/>
								</picture>
								{holder.holder.slice(0,2) == '0x' 
								? <p><span className={styles.holderName}>{ holder.holder.slice(0,5)+'...'+holder.holder.slice(-5) }</span></p>
								: <span className={styles.holderName}>{ holder.holder }</span>
								}
								</span>
								<span className={styles.remainingGamesListRight}>
									{
										holder.warriors < 10
											? "0" + holder.warriors
											: holder.warriors
									}
								</span>
							</li>
				}))
				: <PuffLoader
					color={'#26ded0'}
						loading={loading}
						cssOverride={override}
						size={50}
						aria-label="sync Spinner"
						data-testid="sync"
					/>
			}			
			<li key="footerRemainingList" className={styles.remainingGamesListFooter}>
				<span className={styles.remainingGamesListLeft}>
					Total Holders : { dataRemaining?.totalHolders }
				</span>
				<span className={styles.remainingGamesListRight}>
					Total Warriors : { dataRemaining?.totalWarriors }
				</span>
			</li>
		</ul>
	</div>
	)
}