import Image from 'next/image'
import { useEffect, useState } from 'react'
import logo from '../public/images/POKMI_LOGO.png'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import styles from '../../styles/CountdownWar.module.scss' 
import { useWalletContext } from '../../utils/wallet'
import { truncateWallet } from '../../utils'
import { truncate } from 'fs'
import { useRouter } from 'next/router'
import moment from 'moment'

export default function CountdownRegistrationWar(props){
	const { isLog, timeToStartWar } = props;
	const [days, setDays] = useState(0);
	const [hours, setHours] = useState(0);
	const [minutes, setMinutes] = useState(0);
	const [seconds, setSeconds] = useState(0);
	const [timerDesignation, setTimerDesignation] = useState("Time before the War.");
	useEffect(() => {
		const eventTime = moment(timeToStartWar);
		const currentTime = moment();
		let duration = moment.duration(eventTime.diff(currentTime));
		
		const interval = setInterval(() => {
			duration = moment.duration(
				duration.asMilliseconds() - 1000,
				"milliseconds"
			);
			setDays(duration.days())
			setHours(duration.hours())
			setMinutes(duration.minutes())
			setSeconds(duration.seconds())
		}, 1000);
		console.log('seconds', seconds)
		return () => clearInterval(interval);
	}, [])
  return (
	<div className={styles.containerCountdownWar}>
		<span className={isLog ? styles.countdownDesignation : styles.countdownDesignationOnly}>{ timerDesignation }</span>
		<p className={styles.countdownValueDays}>
			<span>{ days > 9 ? days : `0${days}` }</span>
			<span className={styles.span} data-text="s">days</span>
		</p>
		<p className={styles.countdownValueHours}>
			<span>{ hours > 9 ? hours : `0${hours}` }</span>
			<span className={styles.span} data-text="s">hours</span>
		</p>
		<p className={styles.countdownValueMinutes}>
			<span>{ minutes > 9 ? minutes : `0${minutes}` }</span>
			<span className={styles.span} data-text="n">min</span>
		</p>
		<p className={styles.countdownValueSeconds}>
			<span>{ seconds > 9 ? seconds : `0${seconds}` }</span>
			<span className={styles.span} data-text="c">sec</span>
		</p>
	</div>
  )
}