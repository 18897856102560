import Image from 'next/image'
import { useEffect, useState } from 'react'
import logo from '../public/images/POKMI_LOGO.png'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import styles from '../../styles/CountdownNextWar.module.scss' 
import { useWalletContext } from '../../utils/wallet'
import { truncateWallet } from '../../utils'
import { truncate } from 'fs'
import { useRouter } from 'next/router'
import moment from 'moment'

export default function CountdownNextWar(props){
	const { timeForNextWar } = props;
	
	const [days, setDays] = useState(0);
	const [hours, setHours] = useState(0);
	useEffect(()=>{
		const eventTime = moment(timeForNextWar);
		const currentTime = moment();
		let duration = moment.duration(eventTime.diff(currentTime));

		const interval = setInterval(() => {
			duration = moment.duration(
				duration.asMilliseconds() - 1000,
				"milliseconds"
			);
			setDays(duration.days())
			if(duration.days() == 0){
				setHours(duration.hours())
			}
		}, 1000);
		return () => clearInterval(interval);
	}, [])
  return ( <div className={styles.containerInfoWar}>
		<div className={styles.containerCountdownNextWar}>
				<h5 className={styles.h5}>Season in preparation.</h5>
				<span className={styles.descNextWar}>Time before the next season</span>
				{days != 0 && <p className={styles.countdownValueDays}>
					<span>{ days >= 10 ? days : `0${days}` }</span>
					<span className={styles.span} data-text="ys">days</span>
				</p>}
				{hours != 0 && <p className={styles.countdownValueHours}>
					<span>{ hours >= 10 ? hours : `0${hours}` }</span>
					<span className={styles.span} data-text="rs">hours</span>
				</p>}
			</div>
		</div>
  )
}