import Image from 'next/image'
import { useEffect, useState } from 'react'
import logo from '../public/images/POKMI_LOGO.png'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import styles from '../../styles/CountdownNextWar.module.scss' 
import { useWalletContext } from '../../utils/wallet'
import { truncateWallet } from '../../utils'
import { truncate } from 'fs'
import { useRouter } from 'next/router'
import moment from 'moment'
import BuyPokumi from '../ActionWar/BuyPokumi'

export default function CountdownEndWar(props){
	// const { timeForNextWar } = props;
	
	// const [days, setDays] = useState(0);
	// useEffect(()=>{
	// 	const eventTime = moment(timeForNextWar);
	// 	const currentTime = moment();
	// 	let duration = moment.duration(currentTime.diff(eventTime));

	// 	const interval = setInterval(() => {
	// 		duration = moment.duration(
	// 			duration.asMilliseconds() - 1000,
	// 			"milliseconds"
	// 		);
	// 		setDays(duration.days())
	// 	}, 1000);
	// 	return () => clearInterval(interval);
	// }, [])
  return (<div className={styles.containerInfoWar}>
			<div className={styles.containerCountdownNextWar}>
				<h5 className={styles.h5}>The last war is over!</h5>
				<span className={styles.descNextWar}>Come back later!</span>
			</div>
			<BuyPokumi />
		</div>
  )
}