import { useEffect, useRef, useState } from 'react'
import type { NextPage } from 'next'
import Head from 'next/head'
import Image from 'next/image'
import styles from '../styles/Home.module.scss'
import { useModalContext } from '../context/modalContext'

import { ToastContainer } from '../components/base/Toaster'
import { Modal } from '@thepokencompany/ui'
import Header from '../components/Header'
import Footer from '../components/Footer'
import NavBar from '../components/NavBar'
import RemainingWar from '../components/RemainingWar'
import RewardsWar from '../components/RewardsWar'
import RulesWar from '../components/RulesWar'
import ActionWar from '../components/ActionWar/ActionWar'
import CountdownNextWar from '../components/CountdownWar/CountdownNextWar'
import CountdownRegistrationWar from '../components/CountdownWar/CountdownRegistrationWar'
import CountdownInProgressWar from '../components/CountdownWar/CountdownInProgressWar'
import CountdownEndWar from '../components/CountdownWar/CountdownEndWar'
import { useWalletContext } from '../context/walletContext'
import { useUserContext } from '../context/userContext'
import { useConnectContext } from '../context/connectContext'
import { useWarContext } from '../context/warContext'
import moment from 'moment'

const Home: NextPage = () => {
	const { content, onBack: onBackModal, backModal, closeModal, preventClose } = useModalContext()
	// const { startingSeason } = useWalletContext()
	const { war, warriors, warriorsFetched, remainingFetch, sendWarrior } = useWarContext()
	const { user, disconnect } = useUserContext()
	const { openLogin } = useConnectContext()
	const [beforeWarCount, setBeforeWarCount] = useState(false)
	const [registrationForWar, setRegistrationForWar] = useState(false);
	const [warInProgress, setWarInProgress] = useState(false)
	const [lastDayWar, setLastDayWar] = useState<Date>()
	const [lastWarEnd, setLastWarEnd] = useState(false)
	const [isLogging, setIsLogging] = useState(false)
	const [remaining, setRemaining] = useState(null)
	const scrollTo = (ref) => {
		window.scrollTo({ behavior: 'smooth', top: ref.current.offsetTop })
	}
	useEffect(() => {
		if(war?.registration_date && war?.starting_date){
			setBeforeWarCount(moment().isBefore(war?.registration_date))
			setRegistrationForWar(moment().isBetween(war?.registration_date, war?.starting_date, 'days', '[)'))
			if(war?.nbr_of_day){
				setLastDayWar(moment(war?.starting_date).add(war?.nbr_of_day,'days').toDate());
				setWarInProgress(
					moment().isBetween(
						war?.starting_date, 
						moment(war?.starting_date).add(war?.nbr_of_day,'days'),
						'days',
						'[]'
					))
			}
			if(war?.winner){
				setLastWarEnd(true)
				setWarInProgress(false)
			}
		}
	}, [war])
	useEffect(() => {
		console.log('remainingFetch', remainingFetch)
		if(war?.remaining){
			setRemaining(war?.remaining);
		}
	}, [remainingFetch, war?.remaining])
	useEffect(() => {
		user ? setIsLogging(true) : setIsLogging(false)
	}, [user])
	return (
		<div className='background' >
		<Head>
			<title>Pokumi War</title>
			<meta name="description" content="Welcome to Pokumi War." />
			<link rel="icon" href="/favicon.ico" />
		</Head>
		<Header user={user} onConnect={openLogin} onDisconnect={disconnect}/>
		<main className={styles.containerMain}>
			{/* <NavBar /> */}
			{/* {isLogging && <button onClick={() => startingSeason(97)}>test</button>} */}
			<h1 className={styles.h1} data-text="Warriors.">Start the War with your Pokumi Warriors.</h1>
			<div className={styles.decoOne}>
				<picture className={styles.warriorsPict}>
					<source srcSet="/images/Pokumiwarrior33.png" type="image/png"/>
					<img  alt="mashed deco"  src="/images/Pokumiwarrior33.png" />
				</picture>
				<picture className={styles.mashed}>
					<source srcSet="/mashed/mashed_top_left.svg" type="image/svg"/>
					<img  alt="mashed deco"  src="/mashed/mashed_top_left.svg" />
				</picture>
			</div>
			{ beforeWarCount &&
			  <CountdownNextWar timeForNextWar={ war?.registration_date }/>
			}
			{ registrationForWar &&
				<>
					<CountdownRegistrationWar isLog={isLogging} timeToStartWar={war?.starting_date} />
					{isLogging && <ActionWar playerWarriors={warriors} fetchWarriors={warriorsFetched} onConfirm={sendWarrior}/>}
				</>
			}
			{ warInProgress &&
				<CountdownInProgressWar timeProgress={lastDayWar}/>
			}
			{ lastWarEnd && 
				<CountdownEndWar />
			}
			<div className={styles.decoTwo}>
				<picture className={styles.warriorsPict}>
					<source srcSet="/images/Pokumiwarrior33.png" type="image/png"/>
					<img  alt="mashed deco"  src="/images/Pokumiwarrior33.png" />
				</picture>
				<picture className={styles.mashed}>
					<source srcSet="/mashed/mashed_top_right.svg" type="image/svg"/>
					<img  alt="mashed deco"  src="/mashed/mashed_top_right.svg" />
				</picture>
			</div>
			 <RewardsWar />
			<RulesWar />
			{ (registrationForWar || warInProgress) && <RemainingWar dataRemaining={remaining} nbrOfDay={war.nbr_of_day}/> }
			<div className={styles.containerWinnerWar}>
				<h5 className={styles.h5} data-text="ner.">Winner.</h5>
				<div className={styles.winnerName}>
					<span>{war?.winner 
							 ? war?.winner.slice(0,2) == '0x' 
								? <p><span>{ war?.winner.slice(0,5)+'...'+war?.winner.slice(-5) }</span></p>
								: <span >{ war?.winner }</span>
							 : 'not yet selected'}</span>
				</div>
			</div>
		</main>
		<Footer />
		<ToastContainer />

		<Modal open={!!content} onClose={closeModal} preventClose={preventClose}>
        {content}
      </Modal>
		</div>
	)
}

export default Home
