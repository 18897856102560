import Image from 'next/image'
import { useEffect, useState } from 'react'
import logo from '../public/images/POKMI_LOGO.png'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import styles from '../../styles/CountdownNextWar.module.scss' 
import { useWalletContext } from '../../utils/wallet'
import { truncateWallet } from '../../utils'
import { truncate } from 'fs'
import { useRouter } from 'next/router'
import moment from 'moment'

export default function CountdownInProgressWar(props){
	const { timeProgress } = props;
	
	const [days, setDays] = useState(0);
	useEffect(()=>{
		const eventTime = moment(timeProgress);
		const currentTime = moment();
		let duration = moment.duration(eventTime.diff(currentTime));

		const interval = setInterval(() => {
			duration = moment.duration(
				duration.asMilliseconds() - 1000,
				"milliseconds"
			);
		setDays(duration.days())
		}, 1000);
		return () => clearInterval(interval);
	}, [])
  return (<div className={styles.containerInfoWar}>
			<div className={styles.containerCountdownNextWar}>
				<h5 className={styles.h5}>Registration are now closed.</h5>
				<span className={styles.descNextWar}>Time before the end of the season</span>
				<p className={styles.countdownValueDays}>
					<span>{ days }</span>
					<span className={styles.span} data-text="ys">days</span>
				</p>
			</div>
		</div>
  )
}