import Image from 'next/image'
import { useState } from 'react'
import logo from '../public/images/POKMI_LOGO.png'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import styles from '../styles/RulesWar.module.scss' 
import { useWalletContext } from '../utils/wallet'
import { truncateWallet } from '../utils'
import { truncate } from 'fs'
import { useRouter } from 'next/router'



export default function RulesWar(props){
	return (
	<div className={styles.containerRulesWar}>
		<h5 className={styles.h5}>Rules.</h5>
		<p className={styles.rulesParagraph}>
			<span data-text="." className={styles.rulesWarIndex}>1.</span>
			<span className={styles.rulesWarText}
				>Depending on the rarity level of your Pokumi and its properties, 
				you will receive 1 to 10 Pokumi warriors.</span>
		</p>
		<p className={styles.rulesParagraph}>
			<span data-text="." className={styles.rulesWarIndex}>2.</span>
			<span className={styles.rulesWarText}
				>You can decide to send some or all of your warriors to the war for the season, 
				or decide to keep them for a next season. 
				The more warriors you send, the more chances you have to win the season.</span>
		</p>
		<p className={styles.rulesParagraph}>
			<span data-text="." className={styles.rulesWarIndex}>3.</span>
			<span className={styles.rulesWarText}
				>Once the registration for a season is closed, the war begins. 
				The warriors fight until there is only one left. 
				The owner of the last warrior wins the prize.</span>
		</p>
	</div>
	)
}